import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../components/theme/Layout'
import Footer from '../../components/theme/Footer'
import Header from '../../components/theme/Header'
import BlueHeader from '../../components/common/BlueHeader'
import RenderBlock from '../../components/blocks/RenderBlock'
import Container from '../../components/common/Container'
import FAQListing from '../../components/pages/FAQListing'

const FAQ = ({ data }) => (
  <Layout
    meta={data.defaultPage.meta}
    urls={data.defaultPage.urls}
    locale='zh-hans'
  >
    <Header
      urls={data.defaultPage.urls}
      locale='zh-hans'
    />

    <BlueHeader title={data.defaultPage.title} />

    <Container width={830}>
      <FAQListing
        locale='en'
        index={data.siteSearchIndex.index}
        original={data.allFaqPage.edges}
      />
    </Container>

    <RenderBlock blocks={data.defaultPage.blocks} />

    <Footer />

  </Layout>
)

export const query = graphql`
query {
  defaultPage(guid: { eq: "c1ba4863-8f29-4802-bd3d-0f74c7244288" }, locale:{eq:"zh-hans"}) {
    title
    title_html
    subtitle
    description
    blocks {
      __typename
      ...productCategoriesBlockFragment
    }
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    meta {
      description
      keywords
    }
  }
  allFaqPage(filter:{locale:{eq:"zh-hans"}}) {
    edges {
      node {
        guid
        question
        answer
        category
      }
    }
  }
  siteSearchIndex {
    index
  }
}
`

export default FAQ
